import {
  handleErrorWithSentry,
  // replayIntegration
} from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

// ? Consider switching to Sentry telemetry (perf/issues monitoring) from Vercel Speed Insights. Currently disabled
Sentry.init({
  dsn: 'https://ddfaec5734b4ccd209d8212132abe601@o4507947332272128.ingest.us.sentry.io/4507947338629120',
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
